import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout/Layout'
import HeaderSection from '../sections/contact/HeaderSection'
import ContactSection from '../sections/contact/ContactSection'
import { breakpoint } from '../configs/utilities'

const Contact = () => {
  return (
    <Layout pageName="contact">
      <Wrapper>
        <HeaderSection />
        <ContactSection />
      </Wrapper>
    </Layout>
  )
}

export default Contact

const Wrapper = styled.div`
  min-height: 70vh;
  @media screen and (min-width: ${breakpoint.md}) {
    padding: 10vh 0 0 0;
  }
  @media screen and (min-width: ${breakpoint.huge}) {
    min-height: auto;
    padding: 150px 0 150px 0;
  }
`
