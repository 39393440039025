import React, { useState } from 'react'
import styled from 'styled-components'
import { breakpoint, color, transition, opacity } from '../../../configs/utilities'
import { injectIntl } from 'gatsby-plugin-react-intl'
import TextField from '@mui/material/TextField'
import { useWindowSize } from '../../../configs/getGlobalWindowSize'
import emailjs from '@emailjs/browser'

const ContactForm = ({ intl }) => {
  const currentWidth = useWindowSize().width
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const [selectedData, setSelectedData] = useState({
    name: '',
    email: '',
    message: '',
  })

  const handleData = (e) => {
    const newData = { ...selectedData }
    newData[e.target.name] = e.target.value
    setSelectedData(newData)
  }

  let formValues = {
    name: selectedData.name,
    email: selectedData.email,
    message: selectedData.message,
  }

  const onSubmit = (e) => {
    e.preventDefault()

    setLoading(true)
    emailjs.send('service_5mvwwe4', 'template_exyeu3n', formValues, 'dgfd-7q6XZkYlxOLw').then(
      (response) => {
        setLoading(false)
        setSuccessMessage(intl.formatMessage({ id: 'contact.success' }))
        setSelectedData('')
        setTimeout(() => setSuccessMessage(''), 10000)
      },
      (error) => {
        setLoading(false)
        setErrorMessage(intl.formatMessage({ id: 'contact.error' }))
        setTimeout(() => setErrorMessage(''), 10000)
      },
    )
  }

  return (
    <>
      <Wrapper>
        <Form onSubmit={(e) => onSubmit(e)}>
          <InputWrapper>
            <TextField
              name="name"
              type="text"
              size={currentWidth > 768 ? 'medium' : 'small'}
              color="info"
              disabled={loading}
              label={intl.formatMessage({ id: 'contact.name' })}
              variant="standard"
              fullWidth
              value={selectedData.name || ''}
              onChange={(e) => handleData(e)}
            />
            <TextField
              name="email"
              type="email"
              size={currentWidth > 768 ? 'medium' : 'small'}
              color="info"
              disabled={loading}
              required
              label={intl.formatMessage({ id: 'contact.email' })}
              variant="standard"
              fullWidth
              value={selectedData.email || ''}
              onChange={(e) => handleData(e)}
            />
          </InputWrapper>
          <InputSingle>
            <TextField
              name="message"
              rows= {5}
              color="info"
              disabled={loading}
              label={intl.formatMessage({ id: 'contact.description' })}
              multiline
              variant="standard"
              fullWidth
              value={selectedData.message || ''}
              onChange={(e) => handleData(e)}
            />
          </InputSingle>
          <ButtonWrapper>
            <Button variant="contained" type="submit" color="primary" size="large">
              {intl.formatMessage({ id: 'contact.send' })}
            </Button>
          </ButtonWrapper>
          <MessageBox>
            <MessageSuccess>{successMessage ? successMessage : ''}</MessageSuccess>
            <MessageError>{errorMessage ? errorMessage : ''}</MessageError>
          </MessageBox>
          
        </Form>
      </Wrapper>
    </>
  )
}

export default injectIntl(ContactForm)

const Wrapper = styled.div`
  width: 100%;
  @media screen and (min-width: ${breakpoint.md}) {
    max-width: 600px;
  }
  @media screen and (min-width: ${breakpoint.xxl}) {
    max-width: 1000px;
  }
`

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
    column-gap: 25px;
  }
`

const InputSingle = styled.div`
  width: 100%;
`


const MessageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  font-weight: 400;
  min-height: 80px;
  font-size: 16px;
  text-align: center;

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 16px;
    max-width: 350px;
  }
  `
const MessageSuccess = styled.span`
  color: ${color.blue};
  `

const MessageError = styled.span`
  color: ${color.red};
  `

const ButtonWrapper = styled.div`
  padding-top: 20px;
`

const Button = styled.button`
  width: 140px;
  height: 40px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${color.white};
  background: ${color.blue};
  outline: none;
  border: none;
  cursor: pointer;
  transition: ${transition.default};
  @media (hover: hover) {
    &:hover {
      opacity: ${opacity.default};
    }
  }
`
