import React from 'react'
import styled from 'styled-components'
import { ContentWrapper } from '../../components/common/wrappers/Wrappers'
import ContactForm from '../../components/common/contact/ContactForm'

const ContactSection = () => {
  return (
    <ContentWrapper>
      <Wrapper>
        <ContactForm />
      </Wrapper>
    </ContentWrapper>
  )
}

export default ContactSection

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`
